import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SaveFilterModal from "./SaveFilterModal";
import DeleteFilterModal from "./DeleteFilterModal";
import SavedFilterSelect from "./SavedFilterSelect";
import SavedFiltersList from "./SavedFiltersList";
import { Button, Modal, ScrollView } from "@bbdevcrew/bb_ui_kit_fe";
import { ConfirmFilterOverrideModal } from "./ConfirmFilterOverrideModal";

import {
  postSavedFilterAction,
  deleteSavedFilterAction,
  resetSavedFiltersDataAction,
  putSavedFilterAction,
} from "@store/savedFilters/actions";
import { savedFilterValueSelector } from "@store/filters/selectors";
import {
  postSavedFilterPendingSelector,
  postSavedFilterSuccessfulSelector,
} from "@store/savedFilters/selectors";

import { useSavedFiltersList } from "@utils/useSavedFiltersList";

import s from "./SavedFilters.module.less";

import { useForm } from "antd/lib/form/Form";
import { IFilters } from "@store/filters/types";
import { IFilterItem } from "../../AppFilters.type";
import { ISaveForm, ISavedFiltersProps } from "./types";

export type FiltersModalViewType = "list" | "save" | "edit" | false;

export const SavedFilter: React.FC<ISavedFiltersProps> = ({
  onSavedChange,
  children,
  useInSidebar,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = useForm<ISaveForm>();
  const savedFilters = useSavedFiltersList();

  const [modalView, setModalView] = useState<FiltersModalViewType>(false);
  const [isDeleteFilterModalOpen, setIsDeleteFilterModalOpen] = useState(false);
  const [isConfirmOverrideModalOpen, setIsConfirmOverrideModalOpen] = useState(false);
  const [deleteFilterItem, setDeleteFilterItem] = useState<IFilterItem>();
  const [editFilterItem, setEditFilterItem] = useState<IFilterItem>();
  const [newFilterItem, setNewFilterItem] = useState<{ formData: ISaveForm; filters: IFilters }>();

  const savedFilterValue = useSelector(savedFilterValueSelector);
  const isPostSavedPending = useSelector(postSavedFilterPendingSelector);
  const isPostSuccessful = useSelector(postSavedFilterSuccessfulSelector);

  const handleSaveFilters = (formData: ISaveForm, filters: IFilters, overwrite?: boolean) => {
    const { name, color, access_scope, id } = formData;

    const payload = {
      name,
      color,
      access_scope,
      overwrite,
      request: filters,
    };

    return dispatch(
      id
        ? putSavedFilterAction({
            id,
            ...payload,
          })
        : postSavedFilterAction(payload),
    );
  };

  const handleFilterFormSubmit = (formData: ISaveForm, filters: IFilters, overwrite?: boolean) => {
    const shouldConfirm = !overwrite && savedFilters.some(filter => filter.name === formData.name);

    if (shouldConfirm) {
      setIsConfirmOverrideModalOpen(true);
      setNewFilterItem({
        formData,
        filters,
      });
    } else {
      handleSaveFilters(formData, filters, overwrite);
    }
  };

  const onDeleteFilter = () => {
    deleteFilterItem?.id && dispatch(deleteSavedFilterAction(deleteFilterItem?.id.toString()));

    setIsDeleteFilterModalOpen(false);
  };

  const onSavedFilterModalCancel = () => {
    form.resetFields();
    setModalView("list");
    dispatch(resetSavedFiltersDataAction());
  };

  const onSavedModalClick = () => {
    setModalView("save");
  };

  const handleDeleteSavedFilter = (item: IFilterItem) => {
    setDeleteFilterItem(item);
    setIsDeleteFilterModalOpen(true);
  };

  const handleEditSavedFilter = (item: IFilterItem) => {
    setEditFilterItem(item);
    setModalView("edit");
  };

  const handleConfirmOverrideClose = () => {
    setIsConfirmOverrideModalOpen(false);
    setNewFilterItem(undefined);
  };

  const handleConfirmOverrideOk = () => {
    if (newFilterItem) {
      handleSaveFilters(newFilterItem.formData, newFilterItem.filters, true);
    }
    handleConfirmOverrideClose();
  };

  const formModalProps = {
    confirmLabel: t("pages:dashboard:modals:savesSearch:saveButton"),
    cancelLabel: t("pages:dashboard:modals:savesSearch:cancelButton"),
    confirmLoading: isPostSavedPending,
    onOk: () => {
      form.submit();
    },
    onCancel: onSavedFilterModalCancel,
  };

  const modalProps = {
    save: {
      title: t("pages:dashboard:modals:savesSearch:title"),
      ...formModalProps,
    },
    edit: {
      title: t("pages:dashboard:modals:savesSearch:editTitle"),
      ...formModalProps,
    },
    list: {
      hideFooter: true,
      hideHeader: true,
      hideCloseIcon: true,
    },
  };

  const showConfirmOverrideModal = isConfirmOverrideModalOpen && !!newFilterItem;

  useEffect(() => {
    if (isPostSuccessful) {
      form.resetFields();
      if (modalView === "save" || modalView === "edit") {
        setModalView("list");
      }
    }
    // eslint-disable-next-line
  }, [isPostSuccessful, form, setModalView]);

  return (
    <>
      <div className={s.bbSavedFilter}>
        {!useInSidebar && (
          <Button
            _size="sm"
            _type="custom"
            onClick={onSavedModalClick}
            className={s.bbSavedFilterBtn}
          >
            {t("generic:save")}
          </Button>
        )}
        <SavedFilterSelect
          value={String(savedFilterValue)}
          onChange={newValue => onSavedChange(newValue)}
          onShowAll={() => setModalView("list")}
        >
          {({ open }) => children?.({ open }) || null}
        </SavedFilterSelect>
        <Modal
          width={600}
          onCancel={() => setModalView(false)}
          noPadding
          open={!!modalView}
          {...(modalView ? modalProps[modalView] : {})}
        >
          <ScrollView className={s.bbSavedFilterModal}>
            <>
              {modalView === "save" && (
                <SaveFilterModal onSaveFilter={handleFilterFormSubmit} form={form} />
              )}
              {modalView === "edit" && editFilterItem && (
                <SaveFilterModal
                  onSaveFilter={handleFilterFormSubmit}
                  savedFilterItem={editFilterItem}
                  form={form}
                />
              )}
              {modalView === "list" && (
                <SavedFiltersList
                  onAddClick={() => setModalView("save")}
                  onEditClick={filterItem => handleEditSavedFilter(filterItem)}
                  onDeleteClick={filterItem => handleDeleteSavedFilter(filterItem)}
                />
              )}
            </>
          </ScrollView>
        </Modal>
        {isDeleteFilterModalOpen && (
          <DeleteFilterModal
            deleteFilterItem={deleteFilterItem}
            onDeleteFilter={onDeleteFilter}
            onDeleteFilterCancel={() => setIsDeleteFilterModalOpen(false)}
          />
        )}
        {showConfirmOverrideModal && (
          <ConfirmFilterOverrideModal
            filterName={newFilterItem.formData.name}
            onCancel={handleConfirmOverrideClose}
            onOk={handleConfirmOverrideOk}
            confirmLabel="Override"
            cancelLabel="Go back"
          />
        )}
      </div>
    </>
  );
};
