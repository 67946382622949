import React, { FC, Fragment, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";

import ClientPlanBadge from "./ClientPlanBadge";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import AppSideBarLabel from "./AppSideBarLabel";
import { AppSideBarFeatures } from "./AppSidebarFeature";
import PresetSelector from "../PresetSelector";
import { SavedFilter } from "../AppFilter/filters/SavedFilters/SavedFilter";

import { meSelector } from "@store/me/selectors";
import { savedFilterValueSelector } from "@store/filters/selectors";
import { getModuleSectionsListAction } from "@store/modules/actions";
import { getAllModulesSectionsSelector } from "@store/modules/selectors";

import { useAppSidebar } from "./helpers";
import { useSavedFiltersList } from "@utils/useSavedFiltersList";
import { getCurrentModuleName } from "@utils/paths";

import s from "./AppSidebar.module.less";

import { IAppSidebarProps } from "./AppSidebar.types";
import { IAvailableSectionsType } from "@store/modules/types";

import { ChevronLeftIcon, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

const AppSidebar: FC<IAppSidebarProps> = ({ hasSavedFilters, onSavedFilterChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const savedFiltersList = useSavedFiltersList();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isCollapsed,
    isExpanded,
    isPreviewMode,
    collapsePanel,
    expandPanel,
    previewPanel,
    ref: sidebarRef,
  } = useAppSidebar();

  const allConversationsText = t(
    "pages:dashboard:modals:savesSearch:filterSelector:allConversations",
  );

  const moduleId = getCurrentModuleName();

  const me = useSelector(meSelector);
  const savedFilterValue = useSelector(savedFilterValueSelector);
  const modulesSections = useSelector(getAllModulesSectionsSelector);

  const sections = modulesSections[moduleId as IAvailableSectionsType];

  const getModuleSectionsList = useCallback(
    (module_id: string) => {
      if (module_id !== "care_cps") {
        dispatch(getModuleSectionsListAction(module_id));
      }
    },
    [dispatch],
  );

  const setActiveSection = useCallback(
    (id: string) => {
      const newParams = new URLSearchParams(new URL(document.location.href).searchParams);
      newParams.set("section", id);
      setSearchParams(newParams);
    },
    [setSearchParams],
  );

  useEffect(() => {
    getModuleSectionsList(moduleId);
  }, [moduleId, getModuleSectionsList]);

  const sectionId = searchParams.get("section") || "";

  useEffect(() => {
    if (!sectionId) {
      const DEFAULT_SECTION = sections
        ?.flatMap(section => section.features)
        .find(feature => feature?.selected)?.id;

      if (DEFAULT_SECTION) setActiveSection(DEFAULT_SECTION);
    }
  }, [searchParams, setActiveSection, sections, sectionId]);

  const savedFilterItem = useMemo(() => {
    return savedFiltersList.find(filter => filter.id.toString() === savedFilterValue?.toString());
  }, [savedFiltersList, savedFilterValue]);

  return (
    <div
      className={classNames(s.bbAppSidebarWrapper, {
        [s.bbAppSidebarCollapsedWrapper]: isCollapsed || isPreviewMode,
      })}
      ref={sidebarRef}
    >
      <div
        className={classNames(s.bbAppSidebar, {
          [s.bbAppSidebarFullWidth]: isPreviewMode || isExpanded,
        })}
      >
        <div id="appSidebar-top"></div>
        <div className={s.bbAppSidebarSections}>
          {sections
            ?.filter(section => !!section.is_visible)
            .map((section, sectionIndex) => (
              <Fragment key={section.id}>
                <div
                  key={section.id}
                  data-section-id={section.id}
                  className={s.bbAppSidebarSection}
                >
                  <div className={s.bbAppSidebarFeatureHeader}>
                    <AppSideBarLabel>{!isCollapsed ? section.label : ""}</AppSideBarLabel>
                    <div id={`appSidebar-section-${section.id}-action`}></div>
                  </div>
                  <div id={`appSidebar-section-${section.id}`}></div>
                  {hasSavedFilters && sectionIndex === 0 && (
                    <SavedFilter onSavedChange={onSavedFilterChange} useInSidebar>
                      {({ open }) => (
                        <PresetSelector
                          open={open}
                          isCollapsed={isCollapsed}
                          value={
                            savedFilterItem
                              ? {
                                  ...savedFilterItem,
                                  label: savedFilterItem?.name || "",
                                }
                              : undefined
                          }
                          allLabel={allConversationsText}
                          className={s.bbAppSidebarPresetSelector}
                        />
                      )}
                    </SavedFilter>
                  )}
                  <AppSideBarFeatures
                    features={section?.features || []}
                    moduleId={moduleId}
                    sectionId={sectionId}
                    onClick={feature => setActiveSection(feature.id)}
                  />
                </div>
                {sectionIndex !== sections.length - 1 && (
                  <div className={s.bbAppSidebarSectionDivider} />
                )}
              </Fragment>
            ))}
        </div>
        <div id="appSidebar-bottom"></div>
        <div className={s.bbClientPlanBadgeWrapper}>
          {!!me?.client?.client_plan && (
            <div onMouseEnter={isCollapsed ? previewPanel : undefined}>
              <ClientPlanBadge type={me.client.client_plan} showTitle={!isCollapsed} />
            </div>
          )}
          {!isCollapsed && (
            <Tooltip
              title={t(
                `components:listen:sidePanel:${
                  isPreviewMode ? "expandTooltip" : "collapsibleTooltip"
                }`,
              )}
            >
              <div
                className={s.bbSidebarCollapseIcon}
                onClick={isPreviewMode ? expandPanel : collapsePanel}
              >
                {isPreviewMode ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppSidebar;
