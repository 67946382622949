import React, { FC, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "antd";
import { Picker, EmojiData } from "emoji-mart";
import { OutsideClickDetector } from "@bbdevcrew/bb_ui_kit_fe";

import "emoji-mart/css/emoji-mart.css";
import s from "./EmojiSelect.module.less";

import { IEmojiSelectProps } from "./EmojiSelect.type";
import { getEmojiSetForPlatform, getCategories } from "./helpers";

import { SmileyIcon } from "@assets/index";

const EmojiSelect: FC<IEmojiSelectProps> = ({ platform, onEmojiSelect, pickerStyle }) => {
  const { t } = useTranslation();

  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);

  const onEmojiClick = (emoji: EmojiData) => {
    setEmojiPickerOpen(false);
    onEmojiSelect(emoji);
  };

  const getPickerStyles = (): React.CSSProperties => {
    if (!parentRef.current) return {};
    const rect = parentRef.current.getBoundingClientRect();
    return {
      position: "fixed",
      bottom: rect.top + 50,
      right: rect.left + 100,
    };
  };

  return (
    <div className={s.bbEmojiPicker} ref={parentRef}>
      <Button
        id="emojis-btn"
        className={s.bbEmojiButton}
        onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
      >
        <SmileyIcon />
      </Button>

      {emojiPickerOpen ? (
        <OutsideClickDetector onOutsideClick={() => setEmojiPickerOpen(false)}>
          <Picker
            style={pickerStyle || getPickerStyles()}
            showPreview={false}
            showSkinTones={false}
            onSelect={onEmojiClick}
            set={getEmojiSetForPlatform(platform)}
            i18n={{
              search: t("generic:search"),
              categories: getCategories(t),
            }}
          />
        </OutsideClickDetector>
      ) : null}
    </div>
  );
};

export default EmojiSelect;
