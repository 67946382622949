import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import NotFoundView from "@containers/NotFoundView/NotFoundView";
import AppLayout from "@components/_common/AppLayout/AppLayout";
import CareCPSInbox from "@components/care-cps/CareCPSInbox";

import { filterDataSelector } from "@store/filters/selectors";
import {
  getCareCpsStreamsSelector,
  getCareCpsStreamsSelectorFailure,
  getCareCpsStreamsSelectorPending,
  getCareCpsStreamsSelectorSuccessful,
  getCurrentCareCpsStreamSelector,
} from "@store/streams/selectors";
import { postModuleSectionsListAction } from "@store/modules/actions";

import { useRoutes } from "@utils/useRoutes";

import { IMenuItem } from "@components/_common/AppHeader/AppHeader.type";
import { IRestrictedRoutesProps } from "./Routes.type";
import {
  getCareCpsStreamsAction,
  resetCareCpsStreamsAction,
  setCareCpsStreamAction,
} from "@store/streams/actions";

const CareCPSRoutes: FC<IRestrictedRoutesProps> = ({ menu, path }) => {
  const { redirectCallback } = useRoutes(path);
  const dispatch = useDispatch();

  const streams = useSelector(getCareCpsStreamsSelector);
  const fetched = useSelector(getCareCpsStreamsSelectorSuccessful);
  const fetching = useSelector(getCareCpsStreamsSelectorPending);
  const fetchFailed = useSelector(getCareCpsStreamsSelectorFailure);
  const filters = useSelector(filterDataSelector);
  const stream = useSelector(getCurrentCareCpsStreamSelector);

  const shouldFetch = !fetched && !fetching && !fetchFailed;

  useEffect(() => {
    if (shouldFetch) {
      dispatch(getCareCpsStreamsAction());
    }
  }, [shouldFetch, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCareCpsStreamsAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fetched && !fetchFailed && streams.length > 0) {
      dispatch(setCareCpsStreamAction(streams[0]));
    }
  }, [fetched, fetchFailed, streams, dispatch]);

  useEffect(() => {
    if (stream) {
      dispatch(
        postModuleSectionsListAction({
          module: "care_cps",
          filters,
          streamId: stream.id,
        }),
      );
    }
  }, [stream, filters, dispatch]);

  return (
    <AppLayout
      basename="care-cps"
      menu={menu as unknown as IMenuItem[]}
      selectedKey={(path && path.replace(/\//g, "")) || "streams"}
    >
      {{
        content: (
          <Routes>
            <>
              <Route
                path="/"
                element={
                  <Navigate
                    replace
                    to={{
                      pathname: "streams",
                      search: window.location.search,
                    }}
                  />
                }
              />
              <Route
                path="streams"
                element={
                  <PrivateRoute>
                    <CareCPSInbox />
                  </PrivateRoute>
                }
              />
              <Route
                path="unauthorized"
                element={<NotFoundView redirectCallback={redirectCallback} type="403" />}
              />
              <Route path="*" element={<NotFoundView redirectCallback={redirectCallback} />} />
            </>
          </Routes>
        ),
      }}
    </AppLayout>
  );
};

export default CareCPSRoutes;
