import React, { memo } from "react";

import AppSidebarPortal from "@components/_common/AppSidebar/AppSidebarPortal";
import { StreamSelector } from "./StreamSelector";

import s from "./CareCPSInbox.module.less";

export const CareCPSInbox = memo(() => {
  return (
    <AppSidebarPortal
      placement="section-streams"
      component={() => <StreamSelector className={s.bbStreamSelector} />}
    />
  );
});

CareCPSInbox.displayName = "CareCPSInbox";
